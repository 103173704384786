import React from 'react'

const Book = () => {
  return (
    <section class="book" id="book">

    <h1 class="heading"> Buchen <span>reserviere einen tisch</span> </h1>

    <form action="">

        <input type="text" placeholder="name" class="box" />
        <input type="email" placeholder="email" class="box" />
        <input type="number" placeholder="nummer" class="box" />

        <textarea name="" placeholder="ihre nachricht..." class="box" id="" cols="30" rows="10"></textarea>

        <input type="submit" value="senden" class="btn" />

    </form>

</section>
  )
}

export default Book