import React from 'react'

import H1 from './../image/home-img-1.png'
import H2 from './../image/home-img-2.png'
import H3 from './../image/home-img-3.png'

const Home = () => {
  return (
    <section class="home" id="home">

    <div class="row">

        <div class="content">
            <h3>Frischer Kaffee am Morgen</h3>
            <a href="#home" class="btn">Kaufe Jetzt</a>
            <a href="https://q-tech.dev/beispiele" class="btn">Q-Tech Dev</a>
        </div>

        <div class="image">
            <img src={H1} class="main-home-image" alt="" />
        </div>

    </div>

    <div class="image-slider">
        <img src={H1} alt="" />
        <img src={H2} alt="" />
        <img src={H3} alt="" />
    </div>

</section>
  )
}

export default Home