import React from 'react'

const Footer = () => {

const year = new Date().getFullYear();

  return (
    <section class="footer">

    <div class="box-container">

        <div class="box">
            <h3>Filialen</h3>
            <a href="#"> <i class="fas fa-arrow-right"></i> Türkiye </a>
            <a href="#"> <i class="fas fa-arrow-right"></i> azerbaijan </a>
            <a href="#"> <i class="fas fa-arrow-right"></i> kazakhstan </a>
            <a href="#"> <i class="fas fa-arrow-right"></i> Germany </a>
            <a href="#"> <i class="fas fa-arrow-right"></i> France </a>
        </div>

        <div class="box">
            <h3>Links</h3>
            <a href="#"> <i class="fas fa-arrow-right"></i> home </a>
            <a href="#"> <i class="fas fa-arrow-right"></i> über uns </a>
            <a href="#"> <i class="fas fa-arrow-right"></i> menü </a>
            <a href="#"> <i class="fas fa-arrow-right"></i> Bewertung </a>
            <a href="#"> <i class="fas fa-arrow-right"></i> buchen </a>
        </div>

        <div class="box">
            <h3>Kontaktdaten</h3>
            <a href="#"> <i class="fas fa-phone"></i> +49 1573 645 32 16 </a>
            <a href="#"> <i class="fas fa-envelope"></i> Info@Q-Tech.dev</a>
            <a href="#"> <i class="fas fa-envelope"></i> Eslingen am Neckar</a>
        </div>

        <div class="box">
            <h3>folge uns</h3>
            <a href="#"> <i class="fab fa-facebook-f"></i> facebook </a>
            <a href="#"> <i class="fab fa-twitter"></i> twitter </a>
            <a href="#"> <i class="fab fa-twitter"></i> twitter </a>
            <a href="#"> <i class="fab fa-instagram"></i> instagram </a>
            <a href="#"> <i class="fab fa-linkedin"></i> linkedin </a>
        </div>

    </div>

    <div class="credit">
    {" "}
        &copy; copyright {year} Q-Tech Dev - Show5. Design by
        <a href="http://Q-tech.dev" rel="nofollow">
          <span>Qualified Technologies Development</span>{" "}</a>
    </div>

</section>
  )
}

export default Footer