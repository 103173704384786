import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";

import P1 from "./../image/pic-1.png";
import P2 from "./../image/pic-2.png";
import P3 from "./../image/pic-3.png";
import P4 from "./../image/pic-4.png";

const Review = () => {
  return (
    <section className="review" id="review">
      <h1 className="heading">
        Bewertungen <span>was kunden sagen</span>
      </h1>
      <Swiper className="swiper review-slider">
        <div className="swiper-wrapper">
          <SwiperSlide>
            <div className="box">
              <i className="fas fa-quote-left"></i>
              <i className="fas fa-quote-right"></i>
              <img src={P1} alt="" />
              <div className="stars">
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
              </div>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore
                aliquid eveniet qui, similique aut sit.
              </p>
              <h3>john deo</h3>
              <span>Zufriedener Kunde</span>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="box">
              <i className="fas fa-quote-left"></i>
              <i className="fas fa-quote-right"></i>
              <img src={P2} alt="" />
              <div className="stars">
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
              </div>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore
                aliquid eveniet qui, similique aut sit.
              </p>
              <h3>john deo</h3>
              <span>Zufriedener Kunde</span>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="box">
              <i className="fas fa-quote-left"></i>
              <i className="fas fa-quote-right"></i>
              <img src={P3} alt="" />
              <div className="stars">
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
              </div>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore
                aliquid eveniet qui, similique aut sit.
              </p>
              <h3>john deo</h3>
              <span>Zufriedener Kunde</span>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="box">
              <i className="fas fa-quote-left"></i>
              <i className="fas fa-quote-right"></i>
              <img src={P4} alt="" />
              <div className="stars">
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
              </div>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore
                aliquid eveniet qui, similique aut sit.
              </p>
              <h3>john deo</h3>
              <span>Zufriedener Kunde</span>
            </div>
          </SwiperSlide>
        </div>
        <div className="swiper-pagination"></div>
      </Swiper>
    </section>
  );
};

export default Review;
