import './App.css';
import Header from './components/Header';
import Home from './components/Home';
import About from './components/About';
import Menu from './components/Menu';
import Review from './components/Review';
import Book from './components/Book';
import Footer from './components/Footer';

function App() {
  return (
    <>
    <Header />
    <Home />
    <About />
    <Menu />
    <Review />
    <Book />
    <Footer />
    </>
  );
}

export default App;