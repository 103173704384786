import React from 'react'

import A from "./../image/about-img.png"
import A1 from "./../image/about-icon-1.png"
import A2 from "./../image/about-icon-2.png"
import A3 from "./../image/about-icon-3.png"

const About = () => {
  return (
    <section class="about" id="about">

    <h1 class="heading"> Über uns <span>warum wir?</span> </h1>    

    <div class="row">

        <div class="image">
            <img src={A} alt="" />
        </div>

        <div class="content">
            <h3 class="title">
Was macht unseren Kaffee zu etwas Besonderem?</h3>
            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Esse et commodi, ad, doloremque obcaecati maxime quam minima dolore mollitia saepe quos, debitis incidunt. Itaque possimus adipisci ipsam harum at autem.</p>
            <a href="#" class="btn">ganzer artikel</a>
            <div class="icons-container">
                <div class="icons">
                    <img src={A1} alt="" />
                    <h3>
Qualitätskaffee</h3>
                </div>
                <div class="icons">
                    <img src={A2} alt="" />
                    <h3>unsere Filialen</h3>
                </div>
                <div class="icons">
                    <img src={A3} alt="" />
                    <h3>Gratisversand</h3>
                </div>
            </div>
        </div>

    </div>

</section>
  )
}

export default About