import React from 'react'

import M1 from "./../image/menu-1.png"
import M2 from "./../image/menu-2.png"
import M3 from "./../image/menu-3.png"
import M4 from "./../image/menu-4.png"
import M5 from "./../image/menu-5.png"
import M6 from "./../image/menu-6.png"

const Menu = () => {
  return (
    <section class="menu" id="menu">

    <h1 class="heading"> unser menü <span>beliebte kaffee sorten</span> </h1>

    <div class="box-container">

        <a href="#" class="box">
            <img src={M1} alt="" />
            <div class="content">
                <h3>besonderer Kaffee</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Magnam, id.</p>
                <span>8.99€</span>
            </div>
        </a>

        <a href="#" class="box">
            <img src={M2} alt="" />
            <div class="content">
                <h3>besonderer Kaffee</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Magnam, id.</p>
                <span>8.99€</span>
            </div>
        </a>

        <a href="#" class="box">
            <img src={M3} alt="" />
            <div class="content">
                <h3>besonderer Kaffee</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Magnam, id.</p>
                <span>8.99€</span>
            </div>
        </a>

        <a href="#" class="box">
            <img src={M4} alt="" />
            <div class="content">
                <h3>besonderer Kaffee</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Magnam, id.</p>
                <span>8.99€</span>
            </div>
        </a>

        <a href="#" class="box">
            <img src={M5} alt="" />
            <div class="content">
                <h3>besonderer Kaffee</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Magnam, id.</p>
                <span>$8.99</span>
            </div>
        </a>

        <a href="#" class="box">
            <img src={M6} alt="" />
            <div class="content">
                <h3>besonderer Kaffee</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Magnam, id.</p>
                <span>8.99€</span>
            </div>
        </a>

    </div>

</section>
  )
}

export default Menu